.react-contexify__item__content {
    padding: 0;
    background-color: transparent!important;
    color: rgba(25, 23, 17, 0.6)!important;
}

.react-contexify__separator {
    margin: 0;
}

.react-contexify .react-contexify__submenu {
    padding:0;
    border-radius: 0.25rem;
    overflow: hidden;
}

.submenu .react-contexify__item__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.react-contexify__submenu-arrow {
    margin-left: auto;
}

.react-contexify .react-contexify__submenu .react-contexify__item__content {
    margin-left: 0;
}
