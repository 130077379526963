@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .form-select-required-error button {
    @apply ring-warning ring-2;
  }

  div[role="row"] {
    @apply hover:bg-opacity-darker;
  }
}

.active-sidebar-item button > div {
  background: rgba(0, 0, 0, 0.1);
}

.form-input-required > label > div::after,
.form-select-required label::after,
.form-select-search-required-wrapper > label > .text-sm::after {
  content: " *";
  color: #b62222;
}

.form-select-search-warning-wrapper div[class$="-control"] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 185, 48, var(--tw-ring-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.form-select-disabled-wrapper {
  cursor: not-allowed;
}

.form-select-search-disabled-wrapper div[class$="-control"] {
  pointer-events: none;
  background-color: rgba(239, 238, 234) !important;
}

.form-select-disabled {
  pointer-events: none;
}

.form-select-disabled > label > div > div > div > div {
  background-color: rgba(239, 238, 234) !important;
}

.form-select-max-height div[class$="-control"] {
  max-height: 100px;
  overflow-y: scroll;
}

textarea:disabled,
.disabled-radio-group-wrapper {
  cursor: not-allowed !important;
  background-color: rgba(239, 238, 234) !important;
}

.disabled-radio-group-wrapper * {
  pointer-events: none;
}

.table-loading div[role="table"] {
  filter: alpha(opacity=40);
  opacity: 0.4;
}

.table-selectable {
  width: 100%;
  overflow-x: auto;
}

.table-selectable > div {
  user-select: auto !important;
}

/*Making sure listboxes (like selection dropdowns) are displayed over sticky table headers.*/
*[role="listbox"] {
  z-index: 20 !important;
  width: 100% !important;
}

.filter-form-input-label > div {
  padding-left: 18px;
}

.filter-form-input-tooltip-icon {
  margin-top: 4px;
  position: absolute;
}

.order-0 {
  order: 0 !important;
}
